import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

export const useComercialPageBottomSection = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { wpPageRo, wpPageEn } = useStaticQuery(graphql`
    query {
      wpPageRo: wpPage(
        template: { templateName: { eq: "Page Comercial" } }
        language: { code: { eq: RO } }
      ) {
        templatePageFields {
          templatePageBottomSection {
            icon {
              localFile {
                url
              }
            }
            title
            programText
            notes
            backgroundImage {
              localFile {
                url
              }
            }
            buttonLink {
              title
              url
              target
            }
          }
        }
      }
      wpPageEn: wpPage(
        template: { templateName: { eq: "Page Comercial" } }
        language: { code: { eq: EN } }
      ) {
        templatePageFields {
          templatePageBottomSection {
            icon {
              localFile {
                url
              }
            }
            title
            programText
            notes
            backgroundImage {
              localFile {
                url
              }
            }
            buttonLink {
              title
              url
              target
            }
          }
        }
      }
    }
  `)

  const pages = {
    en: wpPageEn,
    ro: wpPageRo,
  }

  return (
    pages[language]?.templatePageFields?.templatePageBottomSection ||
    wpPageRo?.templatePageFields?.templatePageBottomSection
  )
}
